import { assign, createMachine } from 'xstate';

import { MachineContext, MachineEvents } from './types';

const createMobileMachine = () =>
  createMachine(
    {
      predictableActionArguments: true,
      id: 'mobile',
      schema: {
        context: {} as MachineContext,
        events: {} as MachineEvents,
      },
      tsTypes: {} as import('./machine.typegen').Typegen0,
      initial: 'init',
      context: {
        authToken: '',
        scopedAuthToken: '',
        device: {
          type: 'mobile',
          hasSupportForWebauthn: false,
        },
      },
      states: {
        init: {
          on: {
            receivedContext: {
              target: 'deviceSupport',
              actions: 'assignInitialContext',
            },
          },
        },
        deviceSupport: {
          always: [
            {
              target: 'newTabRequest',
              cond: context =>
                context.device.type === 'mobile' &&
                context.device.hasSupportForWebauthn,
            },
            {
              target: 'skipLiveness',
            },
          ],
        },
        newTabRequest: {
          on: {
            scopedAuthTokenGenerated: {
              actions: ['assignScopedAuthToken'],
            },
            newTabOpened: {
              target: 'newTabProcessing',
              actions: ['assignTab'],
            },
          },
        },
        newTabProcessing: {
          on: {
            newTabRegisterCanceled: {
              target: 'newTabRequest',
            },
            newTabRegisterSucceeded: {
              target: 'success',
            },
            newTabRegisterFailed: {
              target: 'skipLiveness',
            },
            statusPollingErrored: {
              target: 'newTabRequest',
              actions: ['clearScopedAuthToken'],
            },
          },
        },
        skipLiveness: {
          on: {
            livenessSkipped: {
              target: 'failure',
            },
          },
        },
        success: {
          type: 'final',
        },
        failure: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        assignInitialContext: assign((context, event) => ({
          ...context,
          device: event.payload.device,
          authToken: event.payload.authToken,
        })),
        assignScopedAuthToken: assign((context, event) => ({
          ...context,
          scopedAuthToken: event.payload.scopedAuthToken,
        })),
        assignTab: assign((context, event) => ({
          ...context,
          tab: event.payload.tab,
        })),
        clearScopedAuthToken: assign(context => ({
          ...context,
          scopedAuthToken: undefined,
        })),
      },
    },
  );

export default createMobileMachine;
