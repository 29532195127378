import { useTranslation } from '@onefootprint/hooks';
import { IcoStore24, IcoUser24 } from '@onefootprint/icons';
import { Button } from '@onefootprint/ui';
import React from 'react';
import styled, { css } from 'styled-components';

import { HeaderTitle, InfoBox } from '../../../../components';
import CollectKybDataNavigationHeader from '../../components/collect-kyb-data-navigation-header';
import useCollectKybDataMachine from '../../hooks/use-collect-kyb-data-machine';

const Introduction = () => {
  const [, send] = useCollectKybDataMachine();
  const { allT, t } = useTranslation('pages.introduction');

  const handleClick = () => {
    send({
      type: 'introductionCompleted',
    });
  };

  return (
    <Container>
      <CollectKybDataNavigationHeader />
      <HeaderTitle title={t('title')} subtitle={t('subtitle')} />
      <InfoBox
        items={[
          {
            title: t('guidelines.beneficial-owner.title'),
            description: t('guidelines.beneficial-owner.description'),
            Icon: IcoStore24,
          },
          {
            title: t('guidelines.bo-kyc.title'),
            description: t('guidelines.bo-kyc.description'),
            Icon: IcoUser24,
          },
        ]}
      />
      <Button fullWidth onClick={handleClick}>
        {allT('pages.cta-continue')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing[7]};
    justify-content: center;
    align-items: center;
  `}
`;

export default Introduction;
