import { useTranslation } from '@onefootprint/hooks';
import {
  BeneficialOwnerDataAttribute,
  BusinessDataAttribute,
} from '@onefootprint/types';
import { TextInput } from '@onefootprint/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { BeneficialOwnersData } from '../../../../../../utils/state-machine/types';

type BeneficialOwnerEmailProps = {
  index: number;
};

const BeneficialOwnerEmail = ({ index }: BeneficialOwnerEmailProps) => {
  const { t } = useTranslation('pages.beneficial-owners.form.fields.email');
  const {
    register,
    formState: { errors },
  } = useFormContext<BeneficialOwnersData>();

  const emailErrors =
    errors[BusinessDataAttribute.beneficialOwners]?.[index]?.[
      BeneficialOwnerDataAttribute.email
    ];
  const hasError = !!emailErrors;
  const hint = hasError ? emailErrors?.message : undefined;
  const shouldHide = index === 0;

  return shouldHide ? null : (
    <TextInput
      type="email"
      data-private
      label={t('label')}
      placeholder={t('placeholder')}
      hasError={hasError}
      hint={hint}
      {...register(
        `${BusinessDataAttribute.beneficialOwners}.${index}.${BeneficialOwnerDataAttribute.email}`,
        {
          required: {
            value: true,
            message: t('errors.required'),
          },
        },
      )}
    />
  );
};

export default BeneficialOwnerEmail;
