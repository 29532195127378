import { useTranslation } from '@onefootprint/hooks';
import { IcoTrash16 } from '@onefootprint/icons';
import { Box, IconButton, Typography } from '@onefootprint/ui';
import React from 'react';

type FileEntryProps = {
  file: File;
  onRemove: () => void;
};

const FileEntry = ({ file, onRemove }: FileEntryProps) => {
  const { t } = useTranslation('pages.declarations.doc-upload');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '32px',
      }}
    >
      <Box>
        <Typography variant="body-3">{file.name}</Typography>
      </Box>
      <Box sx={{ minWidth: '32px' }}>
        <IconButton aria-label={t('remove')} onClick={onRemove}>
          <IcoTrash16 color="error" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FileEntry;
