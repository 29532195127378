import { InvestorProfileDI } from '@onefootprint/types';
import React from 'react';

import InvestorProfileNavigationHeader from '../../components/investor-profile-navigation-header';
import useInvestorProfileMachine from '../../hooks/use-investor-profile-machine';
import useSyncData from '../../hooks/use-sync-data';
import useSyncErrorToast from '../../hooks/use-sync-error-toast';
import { EmployedByBrokerageData } from '../../utils/state-machine/types';
import BrokerageEmploymentForm from './components/brokerage-employment-form';

const BrokerageEmployment = () => {
  const [state, send] = useInvestorProfileMachine();
  const { authToken, data } = state.context;
  const { mutation, syncData } = useSyncData();
  const showToast = useSyncErrorToast();

  const handleSubmit = (brokerageEmploymentData: EmployedByBrokerageData) => {
    syncData({
      authToken,
      data: brokerageEmploymentData,
      speculative: true,
      onSuccess: () => {
        send({
          type: 'brokerageEmploymentSubmitted',
          payload: {
            ...brokerageEmploymentData,
          },
        });
      },
      onError: showToast,
    });
  };

  return (
    <>
      <InvestorProfileNavigationHeader />
      <BrokerageEmploymentForm
        isLoading={mutation.isLoading}
        onSubmit={handleSubmit}
        defaultValues={{
          [InvestorProfileDI.employedByBrokerageFirm]:
            data?.[InvestorProfileDI.employedByBrokerageFirm],
        }}
      />
    </>
  );
};

export default BrokerageEmployment;
